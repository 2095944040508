.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.flex {
  /*コレ追加*/
  display: flex;
  /* border: 1px solid #333; */
}

.flex div {
  /* border: 1px solid #333; */
}

.right {
  /* background: #ffd87c; */
}

.left {
  /* background: #9ddbfc; */
}



.movielists {
  display: flex;
  flex-wrap: wrap;
}

.movie {
  width: 25%;
  display: flex;
  flex-flow: column;
}


.page-item.active .page-link {
  background-color: #f8b500;
  border-color: white
}

.page-link {
  /* display: inline-flex;
  align-items: center;
  border-radius: 30px;
  justify-content: center;
  font-weight: 700; */
  color: black;
  /* background-color: #f8b500 */
  /* font-size: 16px;
  height: 40px;
  width: 40px; */
}

/* .pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 20px 6px;
}  */

@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";
/* @import url('https://fonts.googleapis.com/css2?family=BIZ+UDMincho&display=swap'); */

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.logo-image {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-right: 2em;
}

/* .movie-image {
  border: 1px #b1b1b1 solid;
} */